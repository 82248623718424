/* eslint-disable @typescript-eslint/no-var-requires */
import "../shared/styles.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../custom-utilities.css";

// Add your polyfills here
// Note: make sure it executes before any other js
import fromEntries from "object.fromentries";
fromEntries.shim();

if (process.env.NODE_ENV === "development") {
  if (typeof window === "undefined") {
    console.log("mocking on server...");
    const { server } = require("../mocks/server");
    server.listen({ onUnhandledRequest: "bypass" });
  } else {
    console.log("mocking on browser...");
    const { worker } = require("../mocks/browser");
    worker.start({ onUnhandledRequest: "bypass" });
  }
}

function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />;
}

export default MyApp;
